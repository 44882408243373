import React from 'react'
import { motion } from 'framer-motion';

import "./Backdrop.scss";

const Backdrop = () => {

    const backdropAnimation = {
        initial: { y: -800 },
        animate: { y: 0 },
        transition: {
            type: "spring",
            stiffness: 13,
            damping: 9,
            mass: 2,
            delay: 1,
        }
    };

    return (
        <motion.div
            initial={backdropAnimation.initial}
            animate={backdropAnimation.animate}
            transition={backdropAnimation.transition}
            className='backdropDiv'
        >
            <img src="images/seattleSkyline.png" alt="an animated Seattle skyline" className='backdrop' />
        </motion.div>
    )
}

export default Backdrop