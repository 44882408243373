import React, { useEffect, useState } from 'react';
import './About.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const About = () => {
    const { ref, inView } = useInView({
        threshold: 0.6,
        triggerOnce: true
    });

    // Control animations with state but always render the components
    const [animateCaricature, setAnimateCaricature] = useState(false);
    const [animateCrates, setAnimateCrates] = useState(false);
    const [imageVersion, setImageVersion] = useState({
        caricature: "images/caricatureDark.png",
        crates: "images/cratesDark.png",
    });

    useEffect(() => {
        if (inView) {
            setAnimateCaricature(true);
            setTimeout(() => setAnimateCrates(true), 500);

            const flickerSequence = setTimeout(() => {
                const flickers = [
                    () => setImageVersion({ caricature: "images/caricature.png", crates: "images/crates.png" }),
                    () => setImageVersion({ caricature: "images/caricatureDark.png", crates: "images/cratesDark.png" }),
                    () => setImageVersion({ caricature: "images/caricature.png", crates: "images/crates.png" }),
                    () => setImageVersion({ caricature: "images/caricatureDark.png", crates: "images/cratesDark.png" }),
                    () => setImageVersion({ caricature: "images/caricature.png", crates: "images/crates.png" }),
                ];

                flickers.forEach((func, index) => {
                    setTimeout(func, index * 100);
                });
            }, 2000);

            return () => clearTimeout(flickerSequence);
        } else {
            setAnimateCaricature(false);
            setAnimateCrates(false);
        }
    }, [inView]);

    const caricatureVariants = {
        hidden: { x: '-100vw', opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { type: "spring", stiffness: 45, delay: 0.6 } },
    };

    const cratesVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5, delay: 0.3 } },
    };

    return (
        <div className="About" ref={ref}>
            <div className='bioDiv'>
                <p>
                    I'm Brandon, a Seattle-based web developer with a passion for storytelling rooted in my theater background. Transitioning from performing arts to programming, I merge my creative flair with technical expertise to craft captivating web experiences.
                </p>
                <p>
                    Embracing continuous learning and staying ahead of the latest trends, I strive to enrich the digital landscape by creating immersive narratives that engage and inspire. Each project I undertake aims not only to solve problems but also to enchant and leave a lasting impression, making the web a more fascinating and interactive space.
                </p>
            </div>

            <AnimatePresence>
                <motion.img
                    src={imageVersion.caricature}
                    alt=""
                    className='brandon'
                    initial="hidden"
                    animate={animateCaricature ? "visible" : "hidden"}
                    exit="hidden"
                    variants={caricatureVariants}
                />
            </AnimatePresence>
            <AnimatePresence>
                <motion.img
                    src={imageVersion.crates}
                    alt="crates"
                    className='crates'
                    initial="hidden"
                    animate={animateCrates ? "visible" : "hidden"}
                    exit="hidden"
                    variants={cratesVariants}
                />
            </AnimatePresence>
        </div >
    );
}

export default About;
