import React from 'react'
import ProjectCard from './ProjectCard/ProjectCard'
import ProjectCard2 from './ProjectCard2/ProjectCard2'
import projectData from '../../data/projectData.json'

import './ProjectsComp.scss'

const ProjectsComp = () => {
    return (
        <div className='ProjectsComp'>
            {projectData.projects.map((project, index) => {
                const isEven = index % 2 === 0;
                const ProjectComponent = isEven ? ProjectCard : ProjectCard2;

                return (
                    <ProjectComponent
                        key={index}
                        name={project.name}
                        tech={project.tech}
                        description={project.description}
                        live={project.live}
                        github={project.github}
                        photo={project.photo}
                    />
                );
            })}
        </div>
    );
}

export default ProjectsComp;
