import React from 'react'
import Backdrop from './Backdrop/Backdrop';
import Logo from './Logo/Logo';
import LogoRight from './LogoRight/LogoRight';

import "./Hero.scss";



const Hero = ({ heroRef }) => {

    return (
        <div className='hero' ref={heroRef}>
            <h1>Welcome!</h1>
            <img src='images/brickWall.png' alt="" className='brickwall' />
            <Backdrop />
            <div className='logos'>
                <Logo />
                <LogoRight />
            </div>

            <img src="images/stage.png" alt='an animated stage' className='curtains' />

        </div>
    )
}

export default Hero