import React from 'react'
import ContactForm from './ContactForm/ContactForm'

import './Contact.scss'

const Contact = () => {
    return (
        <div className='Contact'>
            <ContactForm />
            <div className='rightDiv'>
                <h2>Want to get in touch?</h2>
                <p>Send me a message and I'll get right back to you!</p>
            </div>
        </div>
    )
}

export default Contact