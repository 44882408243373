import React from 'react';

import { ReactComponent as HtmlIcon } from '../../../icons/html.svg';
import { ReactComponent as CssIcon } from '../../../icons/css.svg';
import { ReactComponent as JavascriptIcon } from '../../../icons/javascript.svg';
import { ReactComponent as SassIcon } from '../../../icons/sass.svg';
import { ReactComponent as ReactIcon } from '../../../icons/react.svg';
import { ReactComponent as ReduxIcon } from '../../../icons/redux.svg';
import { ReactComponent as ThreeIcon } from '../../../icons/three.svg';
import { ReactComponent as NodeIcon } from '../../../icons/node.svg';
import { ReactComponent as JavaIcon } from '../../../icons/java.svg';
import { ReactComponent as TypeIcon } from '../../../icons/typescript.svg';
import { ReactComponent as PythonIcon } from '../../../icons/python.svg';
import { ReactComponent as GitIcon } from '../../../icons/git.svg';

import './SkillsCard.scss';

const ICON_MAP = {
    HTML: HtmlIcon,
    CSS: CssIcon,
    JAVASCRIPT: JavascriptIcon,
    SASS: SassIcon,
    REACT: ReactIcon,
    REDUX: ReduxIcon,
    THREE: ThreeIcon,
    NODE: NodeIcon,
    JAVA: JavaIcon,
    TYPESCRIPT: TypeIcon,
    PYTHON: PythonIcon,
    GIT: GitIcon,
    // add other icons as needed
};

const SkillsCard = ({ name, delay, isVisible }) => {
    const IconComponent = ICON_MAP[name];

    return (
        <div className='SkillsCard' style={{ animationDelay: `${delay}s` }}>
            <div className='spotDiv'>
                <img src='images/spotOff.png' alt='' />
                <img src='images/spotOn.png' alt='' className={`spotOn ${isVisible ? 'visible' : 'not-visible'}`} />
            </div>
            <div className='techDiv'>
                {IconComponent && <IconComponent className={`${isVisible ? 'visible' : 'not-visible'}`} />}
            </div>
            <div className={`techName ${isVisible ? 'visible' : 'not-visible'}`}>
                <p>{name}</p>
            </div>
        </div>
    );
}

export default SkillsCard;
