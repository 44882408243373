import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import "./NavButton.scss";

const NavButton = ({ sectionName, sectionRef, onClick, translateYValue = 0, initialYValue = 0, translateXValue = 0, delay, springProps, positionOffset = 0 }) => {
    const pRef = useRef(null);
    const buttonRef = useRef(null);
    const onImageRef = useRef(null);
    const offImageRef = useRef(null);
    const marqueeButtonRef = useRef(null);

    useEffect(() => {
        const updateButtonDimensions = () => {
            if (pRef.current && buttonRef.current && marqueeButtonRef.current) {
                const pWidth = pRef.current.offsetWidth;
                const pHeight = pRef.current.offsetHeight;
                const padding = 35;
                const paddingHeight = 25;

                buttonRef.current.style.width = `${pWidth + padding}px`;
                const totalHeight = pHeight + paddingHeight;
                marqueeButtonRef.current.style.height = `${totalHeight}px`;

                if (onImageRef.current && offImageRef.current) {
                    onImageRef.current.style.height = `${totalHeight}px`;
                    offImageRef.current.style.height = `${totalHeight}px`;
                }
            }
        };

        updateButtonDimensions();

        const resizeObserver = new ResizeObserver(updateButtonDimensions);
        const currentPRef = pRef.current;
        if (currentPRef) resizeObserver.observe(currentPRef);

        return () => {
            if (currentPRef) resizeObserver.unobserve(currentPRef);
        };
    }, [sectionName]);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
    };

    return (
        <motion.div
            className="NavButtonWrapper"
            initial={{ y: -250 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", delay, stiffness: 50, damping: 20, mass: 3, bounce: 1.5 }}
            style={{ position: 'relative' }}
        >
            <motion.div
                className="NavButton"
                onClick={handleClick}
                ref={buttonRef}
                initial={{ x: translateXValue, y: initialYValue }}
                animate={{ x: translateXValue, y: initialYValue + translateYValue + positionOffset }}
                transition={{ type: "spring", ...springProps }}
            >
                <div className="rope">
                    <img src="/images/navRope.png" alt="Rope" />
                </div>
                <button className="marqueeButton" ref={marqueeButtonRef}>
                    <img className="off" src="/images/navOff.png" alt="Nav Off" ref={offImageRef} />
                    <img className="on" src="/images/navOn.png" alt="Nav On" ref={onImageRef} />
                    <p ref={pRef}>{sectionName.toUpperCase()}</p>
                </button>
            </motion.div>
        </motion.div>
    );
};

export default NavButton;
