import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as Logo2 } from "../../../icons/Logo2.svg"

import "./LogoRight.scss"

const LogoRight = () => {
    const [isFlickering, setIsFlickering] = useState(false);
    const [addClass, setAddClass] = useState(false);

    const LogoRightAnimation = {
        initial: { x: 700 },
        animate: { x: 0 },
        transition: {
            type: "spring",
            stiffness: 12,
            damping: 7,
            mass: 2,
            delay: 2.5,
        }
    };

    useEffect(() => {

        const startDelay = setTimeout(() => {
            setAddClass(true);

            const initialFlickers = async () => {

                for (let i = 0; i < Math.floor(4 + Math.random() * 3); i++) {
                    setIsFlickering(false);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 150));
                    setIsFlickering(true);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 150));
                }
            };

            const sequenceFlicker = async () => {
                await initialFlickers();

                setIsFlickering(true);
                await new Promise(resolve => setTimeout(resolve, 4000 + Math.random() * 2000));

                for (let i = 0; i < Math.floor(2 + Math.random() * 3); i++) {
                    setIsFlickering(false);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 100));

                    setIsFlickering(true);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 100));
                }


                setIsFlickering(true);
                setTimeout(sequenceFlicker, 4000 + Math.random() * 2000);
            };

            sequenceFlicker();
        }, 5000);

        return () => clearTimeout(startDelay);
    }, []);

    const containerClass = addClass ? (isFlickering ? 'logoDiv neon-on' : 'logoDiv neon-off') : 'logoDiv';

    return (
        <motion.div
            initial={LogoRightAnimation.initial}
            animate={LogoRightAnimation.animate}
            transition={LogoRightAnimation.transition}
            className='logoDiv2'
        >
            <Logo2 className={containerClass} />
        </motion.div>
    )
}

export default LogoRight