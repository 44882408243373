import React, { useRef, useState, useEffect, useCallback } from 'react';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import MobileNav from './components/MobileNav/MobileNav';
import Hero from "./components/Hero/Hero";
import About from './components/About/About';
import ProjectsComp from './components/ProjectsComp/ProjectsComp';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

const App = () => {
  const homeRef = useRef(null);
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const resumeRef = useRef(null);
  const contactRef = useRef(null);
  const resumeImageRef = useRef(null); // Ref for the resume animation image

  const sectionRefs = {
    home: homeRef,
    about: aboutRef,
    projects: projectsRef,
    resume: resumeRef,
    contact: contactRef,
  };

  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [heroVisiblePercentage, setHeroVisiblePercentage] = useState(0);

  const handleResize = useCallback(() => setWindowWidth(window.innerWidth), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const preloadImages = (imageUrls) => {
    const loadImage = (url) => new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
    return Promise.all(imageUrls.map(loadImage));
  };

  useEffect(() => {
    const imagesToPreload = [
      './images/brickWall.png',
      './images/seattleSkyline.png',
      './images/stage.png',
    ];

    preloadImages(imagesToPreload).then(() => {
      setIsLoading(false);
      window.dispatchEvent(new Event('resize'));
    });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const currentHeroRef = heroRef.current;
      const observer = new IntersectionObserver(
        (entries) => entries.forEach((entry) => setHeroVisiblePercentage(entry.intersectionRatio)),
        { root: null, rootMargin: '0px', threshold: Array.from(Array(101).keys(), (i) => i / 100) }
      );

      if (currentHeroRef) observer.observe(currentHeroRef);

      return () => {
        if (currentHeroRef) observer.unobserve(currentHeroRef);
      };
    }
  }, [isLoading]);

  const handleNavClick = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDownloadResume = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const timeoutDuration = isSmallScreen ? 1600 : 2000;

    if (resumeImageRef.current) {
      resumeImageRef.current.src = isSmallScreen ? '/images/resumeSmall.png' : '/images/resume.png';
      resumeImageRef.current.style.display = 'block';
      setTimeout(() => {
        resumeImageRef.current.style.display = 'none';
      }, timeoutDuration);
    }

    setTimeout(() => {
      const link = document.createElement('a');
      link.href = '/files/BrandonWalkerResume.pdf';
      link.download = 'BrandonWalkerResume.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, timeoutDuration - 200);
  };

  if (isLoading) return <div className="loading-container">Loading...</div>;

  const isMobile = windowWidth <= 768;

  return (
    <div ref={homeRef} id="home" className="App">
      <div className='NavBar'>
        {isMobile
          ? <MobileNav sectionRefs={sectionRefs} handleDownloadResume={handleDownloadResume} />
          : <Navbar sectionRefs={sectionRefs} handleNavClick={handleNavClick} heroVisiblePercentage={heroVisiblePercentage} handleDownloadResume={handleDownloadResume} />}
      </div>
      <Hero heroRef={heroRef} />
      <div ref={aboutRef} id="about"><About /></div>
      <div ref={projectsRef} id="projects"><ProjectsComp /></div>
      <div ref={resumeRef} id="skills"><Skills /></div>
      <div ref={contactRef} className='ContactSection' id="contact"><Contact /></div>
      <Footer />
      <img className='resumeAnimation' src="/images/resume.png" ref={resumeImageRef} alt="Resume Animation" style={{ display: 'none' }} />
    </div>
  );
};

export default App;
