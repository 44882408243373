import React, { useRef, useState, useEffect } from 'react';
import SkillsCard from './SkillsCard/SkillsCard';
import techStack from '../../data/stackData.json';
import './Skills.scss';

const Skills = () => {
    const carouselRef = useRef(null);
    const resumeImageRef = useRef(null);
    const [currentRotation, setCurrentRotation] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [config] = useState({
        items: techStack.length,
        rotatex: 0,
        rotatez: 0,
        perspective: 360,
        gap: 0.1,
        masklower: 0.4,
        maskupper: 1.8,
    });

    const itemAngle = 360 / config.items;

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % techStack.length;
        setCurrentRotation((prevRotation) => prevRotation - itemAngle);
        setCurrentIndex(newIndex);
        triggerHapticFeedback();
    };

    const handlePrevious = () => {
        const newIndex = (currentIndex - 1 + techStack.length) % techStack.length;
        setCurrentRotation((prevRotation) => prevRotation + itemAngle);
        setCurrentIndex(newIndex);
        triggerHapticFeedback();
    };

    const triggerHapticFeedback = () => {
        if (navigator.vibrate) {
            navigator.vibrate(50);
        }
    };

    const generateItems = () => {
        return techStack.map((tech, index) => (
            <li key={index} style={{ '--index': index }}>
                <SkillsCard key={index} name={tech.name} delay={index * 0.2} isVisible={index === currentIndex} />
            </li>
        ));
    };

    const handleDownloadResume = () => {
        const isSmallScreen = window.innerWidth <= 768;
        const timeoutDuration = isSmallScreen ? 1600 : 2000;

        if (resumeImageRef.current) {
            resumeImageRef.current.style.display = 'block';
            setTimeout(() => {
                resumeImageRef.current.style.display = 'none';
            }, timeoutDuration);
        }

        setTimeout(() => {
            const link = document.createElement('a');
            link.href = '/files/BrandonWalkerResume.pdf';
            link.download = 'BrandonWalkerResume.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, timeoutDuration - 200);
    };

    useEffect(() => {
        const updateImageSource = () => {
            if (resumeImageRef.current) {
                if (window.innerWidth <= 768) {
                    resumeImageRef.current.src = '/images/resumeSmall.png';
                } else {
                    resumeImageRef.current.src = '/images/resume.png';
                }
            }
        };

        updateImageSource();

        window.addEventListener('resize', updateImageSource);

        return () => {
            window.removeEventListener('resize', updateImageSource);
        };
    }, []);

    return (
        <div
            className="Skills"
            ref={carouselRef}
            style={{
                '--total': config.items,
                '--rotate-x': config.rotatex,
                '--rotate-z': config.rotatez,
                '--mask-lower': config.masklower,
                '--mask-upper': config.maskupper,
                '--gap-efficient': config.gap,
                '--perspective': `${config.perspective}px`,
            }}
        >
            <div className="container">
                <div className="carousel-container">
                    <ul className="carousel" style={{ transform: `translate3d(0, 0, var(--radius)) rotateX(var(--rotate-x)) rotateZ(var(--rotate-z)) rotateY(${currentRotation}deg)` }}>
                        {generateItems()}
                    </ul>
                </div>
                <ul className="controller">{Array.from({ length: techStack.length }).map((_, index) => <li key={index}></li>)}</ul>
            </div>
            <div className="navigation">
                <button className="prevButton" onClick={handlePrevious}>{'<'}</button>
                <button className="nextButton" onClick={handleNext}>{'>'}</button>
            </div>
            <div className="download">
                <button className="downloadResume" onClick={handleDownloadResume}>Download Resume</button>
                <img className='resumeAnimation' src="/images/resume.png" ref={resumeImageRef} alt="Resume Animation" />
            </div>
        </div>
    );
};

export default Skills;
