import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './MobileNav.scss';
import NavButton from '../Navbar/NavButton';

const MobileNav = ({ sectionRefs, handleDownloadResume }) => {
    const navContainerRef = useRef(null);
    const navButtonsRef = useRef([]);
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [isNavVisible, setIsNavVisible] = useState(false);

    const adjustImageWidth = () => {
        navButtonsRef.current.forEach(button => {
            if (button) {
                const pTag = button.querySelector('p');
                const imgTag = button.querySelector('img');
                if (pTag && imgTag) {
                    imgTag.style.width = `${pTag.offsetWidth}px`;
                }
            }
        });
    };

    useEffect(() => {
        if (imagesLoaded === navButtonsRef.current.length) {
            adjustImageWidth();
        }
    }, [imagesLoaded]);

    useEffect(() => {
        window.addEventListener('resize', adjustImageWidth);
        return () => window.removeEventListener('resize', adjustImageWidth);
    }, []);

    const handleImageLoaded = () => {
        setImagesLoaded(imagesLoaded + 1);
    };

    const toggleNavVisibility = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        setIsNavVisible(prev => !prev);
        if (!isNavVisible) {
            setTimeout(adjustImageWidth, 0);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!navButtonsRef.current.some(button => button && button.contains(event.target))) {
                setIsNavVisible(false);
            }
        };

        if (isNavVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNavVisible]);

    useEffect(() => {
        document.body.style.overflow = isNavVisible ? 'hidden' : 'visible';
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isNavVisible]);

    useEffect(() => {
        if (isNavVisible && imagesLoaded === navButtonsRef.current.length) {
            setTimeout(adjustImageWidth, 0);
        }
    }, [isNavVisible, imagesLoaded]);

    const handleNavItemClick = (sectionKey) => {
        setIsNavVisible(false);
        const sectionRef = sectionRefs[sectionKey];
        if (sectionRef?.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            if (sectionKey === 'resume') {
                handleDownloadResume();
            }
        }
    };

    const spring = {
        type: "spring",
        stiffness: 60,
        damping: 9,
        delay: 0.9
    };

    const getItemAnimation = (index) => ({
        initial: { x: index % 2 === 0 ? -300 : 300, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: index % 2 === 0 ? 300 : -300, opacity: 0 },
        transition: { ...spring, delay: isNavVisible ? index * 0.1 : (5 - index) * 0.1 }
    });

    return (
        <>
            <motion.div ref={navContainerRef} className={`MobileNav ${isNavVisible ? 'is-visible' : ''}`}>
                <motion.div className='menuButton' initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={spring}>
                    {!isNavVisible && (
                        <>
                            <motion.div className='blur' initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -100, opacity: 0 }} transition={{ type: "spring", delay: 0, stiffness: 30, damping: 12 }} />
                            <motion.div className='blur blur2' initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 0.75, rotate: 90 }} exit={{ y: -100, opacity: 0 }} transition={{ type: "spring", delay: 0, stiffness: 30, damping: 12 }} />
                            <motion.div className='blur blur3' initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -100, opacity: 0 }} transition={{ type: "spring", delay: 0, stiffness: 30, damping: 12 }} />
                        </>
                    )}
                    <NavButton sectionName="MENU" sectionRef={null} onClick={toggleNavVisibility} translateYValue={0} initialYValue={0} translateXValue={0} delay={2} springProps={spring} />
                </motion.div>
                <AnimatePresence>
                    {isNavVisible && (
                        <div className='navItems'>
                            {["home", "about", "projects", "resume", "contact"].map((text, index) => (
                                <motion.div key={text} {...getItemAnimation(index)} className='navButtons' ref={el => navButtonsRef.current[index] = el} onClick={() => handleNavItemClick(text)}>
                                    <button>
                                        <p>{text.toUpperCase()}</p>
                                        <img src="images/mobileOn.png" alt="a lit marquee board" onLoad={handleImageLoaded} />
                                    </button>
                                </motion.div>
                            ))}
                        </div>
                    )}
                </AnimatePresence>
            </motion.div>
            <div className={`backdrop-blur ${isNavVisible ? 'is-visible' : ''}`} />
        </>
    );
};

export default MobileNav;
