import React, { useEffect, useRef } from 'react';
import './ProjectCard2.scss';

const ProjectCard2 = ({ name, tech, description, live, github, photo }) => {

    const canLightRef = useRef(null);
    const projectCardRef2 = useRef(null);
    const ticketFrameLightRef = useRef(null);
    const projectPhotoRef = useRef(null);
    const canDivRef = useRef(null);
    const ticketDivRef = useRef(null);
    const canDarkRef = useRef(null);

    useEffect(() => {
        const updateEffects = () => {
            if (!projectCardRef2.current) return;

            const cardRect = projectCardRef2.current.getBoundingClientRect();
            const cardCenter = cardRect.top + (cardRect.height / 2);
            const viewportHeight = window.innerHeight;
            const centerOfViewport = viewportHeight / 2;
            const thirdOfViewportHeight = viewportHeight / 3;
            const upperBoundary = centerOfViewport - (thirdOfViewportHeight / 2);
            const lowerBoundary = centerOfViewport + (thirdOfViewportHeight / 2);
            const upperMiddleBoundary = upperBoundary + (thirdOfViewportHeight / 3);
            const lowerMiddleBoundary = lowerBoundary - (thirdOfViewportHeight / 3);

            let opacity;

            if (cardCenter < upperBoundary || cardCenter > lowerBoundary) {
                opacity = 0;
            } else if (cardCenter >= upperBoundary && cardCenter < upperMiddleBoundary) {
                opacity = (cardCenter - upperBoundary) / (upperMiddleBoundary - upperBoundary);
            } else if (cardCenter >= upperMiddleBoundary && cardCenter <= lowerMiddleBoundary) {
                opacity = 1;
            } else {
                opacity = (lowerBoundary - cardCenter) / (lowerBoundary - lowerMiddleBoundary);
            }

            [canLightRef.current, ticketFrameLightRef.current, projectPhotoRef.current].forEach(element => {
                if (element) {
                    element.style.opacity = element === projectPhotoRef.current ? Math.max(0.25, opacity) : opacity;
                }
            });

            if (canLightRef.current && canDarkRef.current) {
                let translateYPercentage;
                if (cardCenter >= upperBoundary && cardCenter <= lowerBoundary) {
                    translateYPercentage = 100;
                } else if (cardCenter < upperBoundary) {
                    translateYPercentage = Math.max(0, 100 - ((upperBoundary - cardCenter) / (upperBoundary - (upperBoundary - thirdOfViewportHeight / 1.5))) * 30);
                } else {
                    translateYPercentage = Math.max(0, 100 - ((cardCenter - lowerBoundary) / ((lowerBoundary + thirdOfViewportHeight / 1.5) - lowerBoundary)) * 30);
                }

                translateYPercentage = Math.min(Math.max(translateYPercentage, 0), 100);
                const translateYValue = `${translateYPercentage - 100}%`;

                canLightRef.current.style.transform = `translateY(${translateYValue})`;
                canDarkRef.current.style.transform = `translateY(${translateYValue})`;
            }

            if (ticketDivRef.current) {
                let translateXPercentage;
                if (cardCenter >= upperBoundary && cardCenter <= lowerBoundary) {
                    translateXPercentage = 0;
                } else if (cardCenter < upperBoundary) {
                    translateXPercentage = Math.min(100, 0 - ((upperBoundary - cardCenter) / (upperBoundary - (upperBoundary - thirdOfViewportHeight / 2))) * -30);
                } else {
                    translateXPercentage = Math.min(100, 0 - ((cardCenter - lowerBoundary) / ((lowerBoundary + thirdOfViewportHeight / 2) - lowerBoundary)) * -30);
                }

                ticketDivRef.current.style.transform = `translateX(${translateXPercentage}%)`;
            }
        };

        window.addEventListener('scroll', updateEffects);
        window.addEventListener('resize', updateEffects);
        updateEffects();

        return () => {
            window.removeEventListener('scroll', updateEffects);
            window.removeEventListener('resize', updateEffects);
        };
    }, []);

    return (
        <div ref={projectCardRef2} className='ProjectCard2'>
            <div className='infoDiv2'>
                <h3>{name}</h3>
                <div className='madeWith2'>
                    {tech.map((technology, index) => (
                        <p key={index} className='technology'>{technology}</p>
                    ))}
                </div>
                <p className='description2'>{description}</p>
                <div className='buttonGroup2'>
                    {live && <button className='live2' onClick={() => window.open(live, '_blank', 'noopener,noreferrer')}>LIVE SITE</button>}
                    {github && <button className='github2' onClick={() => window.open(github, '_blank', 'noopener,noreferrer')}>GITHUB</button>}
                </div>
            </div>
            <div ref={ticketDivRef} className='ticketDiv2'>
                <img src="images/ticketDark.png" alt="" className='ticketFrameDark2' />
                <img ref={ticketFrameLightRef} src='images/ticketLight.png' alt='' className='ticketFrameLight2' />
                <img ref={projectPhotoRef} src={photo} alt='' className='projectPhoto2' />
            </div>
            <div ref={canDivRef} className='canDiv2'>
                <img ref={canDarkRef} src="images/canDark2.png" alt="" className='canDark2' />
                <img ref={canLightRef} src='images/canLight2.png' alt='' className='canLight2' />
            </div>
        </div>
    );
};

export default ProjectCard2;
