import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { motion } from 'framer-motion';
import { ReactComponent as LogoLeft } from "../../../icons/Logo1.svg";
import "./Logo.scss";

const Logo = ({ noMotion = false, translateX = 0, opacity = 1, springProps }) => {
    const [isFlickering, setIsFlickering] = useState(false);
    const [addClass, setAddClass] = useState(false);

    const spring = useSpring({
        transform: `translateX(${translateX}px)`,
        opacity: opacity,
        config: springProps,
        from: { transform: `translateX(-350px)`, opacity: 0 }
    });

    useEffect(() => {
        const startDelay = setTimeout(() => {
            setAddClass(true);

            const initialFlickers = async () => {
                for (let i = 0; i < Math.floor(4 + Math.random() * 3); i++) {
                    setIsFlickering(false);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 150));
                    setIsFlickering(true);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 150));
                }
            };

            const sequenceFlicker = async () => {
                await initialFlickers();

                setIsFlickering(true);
                await new Promise(resolve => setTimeout(resolve, 4000 + Math.random() * 2000));

                for (let i = 0; i < Math.floor(2 + Math.random() * 3); i++) {
                    setIsFlickering(false);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 100));

                    setIsFlickering(true);
                    await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 100));
                }

                setIsFlickering(true);
                setTimeout(sequenceFlicker, 4000 + Math.random() * 2000);
            };

            sequenceFlicker();
        }, noMotion ? 0 : 5000);

        return () => clearTimeout(startDelay);
    }, [noMotion]);

    const containerClass = addClass ? (isFlickering ? 'logoDiv neon-on' : 'logoDiv neon-off') : 'logoDiv';

    return noMotion ? (
        <animated.div style={spring} className='logoDiv'>
            <LogoLeft className={containerClass} />
        </animated.div>
    ) : (
        <motion.div
            initial={{ x: -500 }}
            animate={{ x: 0 }}
            transition={{
                type: "spring",
                stiffness: 15,
                damping: 6,
                mass: 2,
                delay: 1.5,
            }}
            className='logoDiv'
        >
            <LogoLeft className={containerClass} />
        </motion.div>
    );
};

export default Logo;
