import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDLW5Ptupb8p0qXf_daiPLUlnepbxr_5OU",
    authDomain: "portfolio-9ed3a.firebaseapp.com",
    projectId: "portfolio-9ed3a",
    storageBucket: "portfolio-9ed3a.appspot.com",
    messagingSenderId: "956111183129",
    appId: "1:956111183129:web:cad4555cd69989645ff988",
    measurementId: "G-D7N0957C22"
};

// Initialize Firebase only if it hasn't been initialized yet
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics }; // Export for use in other parts of the app
