import React, { useState, useEffect, useCallback, useRef } from 'react';
import NavButton from './NavButton';
import Logo from '../Hero/Logo/Logo';
import "./Navbar.scss";

const useCalculateValues = (setValues, setIsInitialized) => {
    const calculateValues = useCallback(() => {
        const viewportWidth = window.innerWidth;

        const minTranslateXValues = {
            home: 102, about: 98, projects: 94, resume: 90, contact: 86
        };

        const maxTranslateXValues = {
            home: 225, about: 221, projects: 215, resume: 210, contact: 204
        };

        let newTranslateYValues = {};
        let newInitialYValues = {};
        let newTranslateXValues = {};

        if (viewportWidth > 768 && viewportWidth <= 1300) {
            const factor = (viewportWidth - 769) / (1300 - 769);
            newTranslateYValues = {
                home: -34 - (53 * factor), about: -12 - (58 * factor), projects: -24 - (76 * factor), resume: -4 - (30 * factor), contact: -28 - (24 * factor)
            };
            newInitialYValues = {
                home: -20 + (20 * factor), about: -50 + (20 * factor), projects: -60 + (20 * factor), resume: -73 - (10 * factor), contact: -50 - (30 * factor)
            };
            newTranslateXValues = {
                home: minTranslateXValues.home + (maxTranslateXValues.home - minTranslateXValues.home) * factor,
                about: minTranslateXValues.about + (maxTranslateXValues.about - minTranslateXValues.about) * factor,
                projects: minTranslateXValues.projects + (maxTranslateXValues.projects - minTranslateXValues.projects) * factor,
                resume: minTranslateXValues.resume + (maxTranslateXValues.resume - minTranslateXValues.resume) * factor,
                contact: minTranslateXValues.contact + (maxTranslateXValues.contact - minTranslateXValues.contact) * factor
            };
        } else if (viewportWidth > 1200) {
            newTranslateYValues = { home: -87, about: -70, projects: -100, resume: -65, contact: -52 };
            newInitialYValues = { home: 0, about: -30, projects: -40, resume: -55, contact: -80 };
            newTranslateXValues = maxTranslateXValues;
        } else {
            newTranslateYValues = { home: 0, about: 0, projects: 0, resume: 0, contact: 0 };
            newInitialYValues = { home: 0, about: 0, projects: 0, resume: 0, contact: 0 };
            newTranslateXValues = minTranslateXValues;
        }

        setValues({
            translateYValues: newTranslateYValues,
            initialYValues: newInitialYValues,
            translateXValues: newTranslateXValues
        });
        setIsInitialized(true);
    }, [setValues, setIsInitialized]);

    useEffect(() => {
        calculateValues();
        window.addEventListener('resize', calculateValues);
        return () => window.removeEventListener('resize', calculateValues);
    }, [calculateValues]);
};

const Navbar = ({ sectionRefs, handleNavClick, heroVisiblePercentage, handleDownloadResume }) => {
    const [values, setValues] = useState({
        translateYValues: {},
        initialYValues: {},
        translateXValues: {}
    });
    const [isInitialized, setIsInitialized] = useState(false);
    const [positionOffset, setPositionOffset] = useState(0);
    const prevScrollY = useRef(window.scrollY);

    useCalculateValues(setValues, setIsInitialized);

    const buttonConfigs = {
        home: { stiffness: 75, damping: 13, mass: 2, bounce: 0.5 },
        about: { stiffness: 85, damping: 15, mass: 3, bounce: 0.5 },
        projects: { stiffness: 60, damping: 20, mass: 5, bounce: 0.5 },
        resume: { stiffness: 80, damping: 15, mass: 2.5, bounce: 0.75 },
        contact: { stiffness: 120, damping: 16, mass: 4, bounce: 0.5 }
    };

    const calculateLogoPosition = () => {
        const startTransition = 0.5;
        const endTransition = 0;

        let translateX = -350;
        let opacity = 0;

        if (heroVisiblePercentage <= startTransition && heroVisiblePercentage > endTransition) {
            const transitionProgress = (startTransition - heroVisiblePercentage) / (startTransition - endTransition);
            translateX = -350 + transitionProgress * 350;
            opacity = transitionProgress;
        } else if (heroVisiblePercentage <= endTransition) {
            translateX = 0;
            opacity = 1;
        }

        return { translateX, opacity };
    };

    const { translateX, opacity } = calculateLogoPosition();

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const scrollDirection = currentScrollY > prevScrollY.current ? 'down' : 'up';
            const effectStrength = Math.min(Math.abs(currentScrollY - prevScrollY.current) / 1, 26);

            setPositionOffset(scrollDirection === 'down' ? effectStrength : -effectStrength);

            prevScrollY.current = currentScrollY;

            setTimeout(() => setPositionOffset(0), 500);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!isInitialized) return <div>Loading...</div>;

    return (
        <>
            <div className='BGblur'></div>
            <div className='navLogo'>
                <Logo translateX={translateX} opacity={opacity} noMotion={true} springProps={{ stiffness: 220, damping: 5, mass: 1, bounce: 2 }} />
            </div>
            <div className="NavbarItems">
                {Object.keys(sectionRefs).map((key, index) => (
                    <NavButton
                        key={key}
                        sectionName={key}
                        sectionRef={sectionRefs[key]}
                        onClick={() => {
                            handleNavClick(sectionRefs[key]);
                            if (key === 'resume') handleDownloadResume();
                        }}
                        translateYValue={(values.translateYValues[key] || 0) * (1 - heroVisiblePercentage)}
                        initialYValue={values.initialYValues[key] || 0}
                        translateXValue={(values.translateXValues[key] || 0) * (1 - heroVisiblePercentage)}
                        delay={index * 0.3 + 2}
                        springProps={buttonConfigs[key]}
                        positionOffset={positionOffset}
                    />
                ))}
            </div>
        </>
    );
};

export default Navbar;
