import React, { useState, useEffect } from 'react';
import './ContactForm.scss';

function ContactForm() {
    const [form, setForm] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const { name, email, message } = form;
        setIsFormValid(name.trim() !== '' && email.trim() !== '' && message.trim() !== '');
    }, [form]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid) {
            alert('Please fill out all fields.');
            return;
        }

        const functionUrl = 'https://us-central1-portfolio-9ed3a.cloudfunctions.net/sendContactEmail';

        try {
            const response = await fetch(functionUrl, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                setForm({ name: '', email: '', message: '' });
                alert('Message sent successfully!');
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending message');
        }
    };

    return (
        <form onSubmit={handleSubmit} className='ContactForm'>
            <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder="Your Name"
            />
            <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Your Email"
            />
            <textarea
                name="message"
                value={form.message}
                onChange={handleChange}
                placeholder="Your Message"
            />
            <button type="submit" disabled={!isFormValid}>Submit</button>
        </form>
    );
}

export default ContactForm;
