import React from 'react';
import { ReactComponent as LinkedIn } from '../../icons/linkedIn.svg';
import { ReactComponent as Email } from '../../icons/eMail.svg';
import { ReactComponent as GitHub } from '../../icons/gitHub.svg';
import './Footer.scss';

const Footer = () => {
    return (
        <div className='Footer'>
            <div className='links'>
                <a href="https://www.linkedin.com/in/walkerbr/" target="_blank" rel="noopener noreferrer">
                    <LinkedIn />
                </a>
                <a href="https://github.com/bcwalke7" target="_blank" rel="noopener noreferrer">
                    <GitHub />
                </a>
                <a href="mailto:walkerbr1120@gmail.com">
                    <Email />
                </a>
            </div>
            <div className='copyright'>
                <p>&copy; 2024 BRANDON WALKER. ALL RIGHTS RESERVED.</p>
            </div>
        </div>
    );
}

export default Footer;
